package com.zegreatrob.coupling.client.components

import kotlin.Boolean
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Fragment
import react.Key
import react.RefObject
import react.popper.PopperInstance
import web.html.HTMLElement

public fun ChildrenBuilder.CouplingPopUp(
  hide: Boolean,
  popperRef: RefObject<HTMLElement>,
  arrowRef: RefObject<HTMLElement>,
  popperInstance: PopperInstance,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {},
) {
    val component = (CouplingPopUp.unsafeCast<FC<CouplingPopUpProps>>())
    component {
         this.hide = hide
  this.popperRef = popperRef
  this.arrowRef = arrowRef
  this.popperInstance = popperInstance
  key?.let { this.key = it }
  Fragment { children() }

         }
}
